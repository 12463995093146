<script setup lang="ts">
import { useUserStore } from "~/stores/user";
import { useInboxStore } from '~/stores/inbox';
import { useAgentStore } from "~/stores/agent";
import dayjs from 'dayjs';

const route = useRoute();
const toast = useToast();
const { $toast, $supabaseClient } = useNuxtApp();
const userStore = useUserStore();
const inboxStore = useInboxStore();
const agentStore = useAgentStore();

interface Notification {
  id: string;
  type: string;
  created_at: string;
  read_at: string;
  first_name?: string;
  whatsapp_conversation_id?: string;
  contact_first_name?: string;
  agent_id?: string;
  agent_name?: string;
  agent_training_status?: string;
}

const isShowSetting = ref(false);
const notifications = ref<Notification[]>([]);

const notificationsGroupByDate = computed(() => {
  return notifications.value.reduce((acc, notification) => {
    const date = dayjs(notification.created_at).format('DD/MMM/YYYY');
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(notification);
    return acc;
  }, {} as Record<string, Notification[]>);
})

async function getAllNotifications() {
  const { data, error } = await $supabaseClient
    .from('user_notifications')
    .select(`
      id,
      type,
      created_at,
      read_at,
      whatsapp_conversation_id,
      contact_first_name,
      agent_id,
      agent_name,
      agent_training_status
    `)
    .eq('user_id', userStore.user?.uid)
    .eq('workspace_id', userStore.user?.workspaceId)
    .order('created_at', { ascending: false });
  if (error) throw error;
  notifications.value = data;
}

async function getNotification(id: string) {
  const { data, error } = await $supabaseClient
    .from('user_notifications')
    .select(`
      id,
      type,
      created_at,
      read_at,
      whatsapp_conversation_id,
      contact_first_name,
      agent_id,
      agent_name,
      agent_training_status
    `)
    .eq('user_id', userStore.user?.uid)
    .eq('workspace_id', userStore.user?.workspaceId)
    .eq('id', id)
    .single();
  if (error) throw error;
  notifications.value.unshift(data);
  if ((route?.path ?? '').startsWith('/agents/') && route.params?.id === data?.agent_id) {
    agentStore.fetchAgent();
    const isTrainingCompleted = data?.type === "agent_training_completed";
    toast.add({
      id: 'training_done',
      title: isTrainingCompleted ? "Agent's training completed successfully" : "Agent training failed",
      description: isTrainingCompleted ? "You can preview the agent now" : "Please revise the sources and re-train the agent",
      icon: 'i-lucide-brain-circuit',
      timeout: 0,
      color: isTrainingCompleted ? 'green' : 'red',
      ui: {
        icon: {
          base: "mt-1",
        }
      },
      ...(isTrainingCompleted
        ? {
          actions: [
            {
              label: "Preview",
              color: "primary",
              variant: "solid",
              to: `/agents/${data?.agent_id}/preview`,
              external: true,
              size: "2xs",
            }
          ] 
        }
        : {}
      ),
    })
  }
}

const notificationChannel = ref<any>();

function subscribeToNotificationChannel() {
  const channel = $supabaseClient
    .channel('notification')
    .on(
      'postgres_changes',
      {
        event: '*',
        schema: 'public',
        table: 'notifications',
        filter: `user_id=eq.${userStore.user?.uid}`,
      },
      (payload: any) => {
        // console.log('Notification: ', payload)
        if (payload.eventType === 'INSERT') {
          getNotification(payload.new.id);
        }
        else if (payload.eventType === 'UPDATE') {
          const index = notifications.value.findIndex((item) => item.id === payload.new.id);
          if (index === -1) return;
          notifications.value[index] = {
            ...notifications.value[index],
            ...payload.new,
          }
        }
      }
    )
    .subscribe((status, err) => {
      if (err) throw err;
      // console.log(`[Notification channel: ${status}]`)
    })
  notificationChannel.value = channel;
}

onBeforeMount(() => {
  Promise.all([
    getAllNotifications(),
    fetchNotificationSetting(),
  ]).then(() => {
    subscribeToNotificationChannel();
  }).catch((error) => {
    throw error;
  })
})

onUnmounted(() => {
  if (notificationChannel.value) {
    $supabaseClient.removeChannel(notificationChannel.value)
  }
})

const unreadNotifications = computed(() => notifications.value.filter(notification => !notification.read_at));

async function markAllAsRead() {
  const { error } = await $supabaseClient
    .from('notifications')
    .update({
      read_at: dayjs().toISOString()
    })
    .eq('user_id', userStore.user?.uid)
    .is('read_at', null);
  if (error) throw error;
  notifications.value = notifications.value.map(notification => ({
    ...notification,
    read_at: dayjs().toISOString()
  }))
}

function redirectToActionUrl(notification: Notification) {
  let url = '';
  switch (notification.type) {
    case 'conversation_assigned':
      url = `/inbox/${notification?.whatsapp_conversation_id}`;
      break;
    case 'conversation_unassigned':
      url = '/inbox';
      break;
    case 'agent_training_completed':
      url = `/agents/${notification?.agent_id}/preview`;
      break;
    case 'agent_training_failed':
      url = `/agents/${notification?.agent_id}`;
      break;
  }
  window.location.href = url;
}

/**
 * Notification setting
 */
async function fetchNotificationSetting() {
  const { data, error } = await $supabaseClient
    .from('user_preferences')
    .select(`
      notify_on_conversation_assignment
    `)
    .eq('id', userStore.user?.uid)
    .single();
  if (error) throw error;
  inboxStore.enableConversationAssignmentNotification = data?.notify_on_conversation_assignment;
}

async function updateNotificationSetting() {
  try {    
    const { error } = await $supabaseClient
      .from('user_preferences')
      .update({
        notify_on_conversation_assignment: inboxStore.enableConversationAssignmentNotification,
      })
      .eq('id', userStore.user?.uid);
    if (error) throw error;
    $toast.success("Notification preference updated successfully");
  } catch (error) {
    $toast.error("Failed to update notification preference. Please try again later or contact support.")
    throw error;
  }
}
</script>

<template>
  <NuxtPopover>
    <NuxtTooltip
      text="Notifications"
    >
      <NuxtChip
        :show="unreadNotifications.length > 0"
        color="red"
        inset
        size="xs"
      >
        <NuxtButton 
          color="white"
          variant="ghost"
          icon="i-lucide-bell"
        />
      </NuxtChip>
    </NuxtTooltip>
    <template #panel>
      <div class="w-72 text-sm text-gray-900">
        <div class="flex items-center justify-between p-2.5">
          <span class="font-medium">
            {{ isShowSetting ? "Notification preferences" : "Notifications" }}
          </span>
          <NuxtButton 
            v-if="unreadNotifications.length > 0"
            variant="link"
            color="primary"
            size="xs"
            :padded="false"
            @click="markAllAsRead"
          >
            Mark all as read
          </NuxtButton>
          <NuxtButton 
            v-else
            variant="link"
            color="gray"
            :icon="isShowSetting ? 'i-lucide-bell-ring' : 'i-lucide-settings'"
            :padded="false"
            size="xs"
            @click="isShowSetting = !isShowSetting"
          />
        </div>
      </div>
      <div 
        v-if="isShowSetting"
        class="w-72"
      >
        <div class="bg-primary-100/75 px-2 py-1.5 text-xs font-semibold text-gray-900 border-b">
          Send me browser notification on
        </div>
        <div class="p-2.5">
          <NuxtFormGroup
            label="Contact assigned or unassigned"
            :ui="{
              wrapper: 'flex justify-between items-center gap-4',
              inner: 'order-none',
              container: 'order-1 mt-0 flex items-center',
              label: {
                base: 'text-[13px]',
              }
            }"
          >
            <NuxtToggle 
              size="sm"
              @change="updateNotificationSetting"
              v-model="inboxStore.enableConversationAssignmentNotification" 
            />
          </NuxtFormGroup>
        </div>
      </div>
      <div 
        v-else-if="notifications.length > 0"
        class="w-72 max-h-80 overflow-y-auto"
      >
        <template
          v-for="(notifications, date) of notificationsGroupByDate"
          :key="date"
        >
          <div class="bg-gray-100 px-2 py-1.5 text-xs font-semibold text-gray-900 border-b">
            {{ relativeDate(date) }}
          </div>
          <div class="divide-y divide-gray-200 ">
            <template
              v-for="notification of notifications"
              :key="notification.id"
            >
              <div 
                class="flex items-start gap-2 p-2.5 cursor-pointer text-[13px] leading-5 text-gray-700"
                :class="notification?.read_at ? 'bg-white' : 'bg-primary-50/60'"
                @click="redirectToActionUrl(notification)"
              >
                <template v-if="(notification?.type ?? '').startsWith('conversation')">
                  <NuxtAvatar 
                    :alt="notification?.contact_first_name"
                    size="sm"
                    class="mt-1"
                    :ui="{
                      size: {
                        sm: 'w-7 h-7',
                      }
                    }"
                  />
                  <p>
                    Contact 
                    <NuxtBadge
                      size="xs"
                      variant="subtle"
                      color="primary"
                    >
                      {{ notification?.contact_first_name }}
                    </NuxtBadge>
                    has been 
                    <span class="font-semibold">{{ notification.type === 'conversation_assigned' ? 'assigned' : 'unassigned' }}</span> {{ notification.type === 'conversation_assigned' ? 'to' : 'from' }} you
                  </p>
                </template>
                <template v-else-if="(notification?.type ?? '').startsWith('agent_training')">
                  <NuxtAvatar 
                    icon="i-lucide-bot"
                    size="sm"
                    class="mt-1"
                    :ui="{
                      size: {
                        sm: 'w-7 h-7',
                      }
                    }"
                  />
                  <p>
                    Agent
                    <NuxtBadge
                      size="xs"
                      variant="subtle"
                      color="violet"
                      class="ring-[#8b5cf640] bg-[#f5f3ff]"
                    >
                      {{ notification?.agent_name }}
                    </NuxtBadge>
                    <template v-if="notification.type === 'agent_training_completed'">
                      training <span class="font-semibold">completed</span> successfully
                    </template>
                    <template v-else>
                      training <span class="font-semibold">failed</span>. Please revise the sources and try again
                    </template>
                  </p>
                </template>
                <div class="w-10 text-gray-500 text-[10px] text-end">
                  {{ dayjs(notification.created_at).format('HH:mm') }}
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
      <div 
        v-else
        class="w-full flex flex-col justify-center items-center gap-1.5 p-4 border-t"
      >
        <p class="font-medium text-sm text-gray-700">
          You're all up to date!
        </p>
        <p class="text-xs text-gray-500">
          There are no new notifications at the moment.
        </p>
      </div>
    </template>
  </NuxtPopover>
</template>

<style scoped>

</style>