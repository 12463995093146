<script setup lang="ts">
import Favicon from '~/assets/img/favicon.png';
import { useUserStore } from "~/stores/user";

const { unreadMessageCount = 0 } = defineProps<{
  unreadMessageCount?: number;
}>();

const route = useRoute();
const userStore = useUserStore();
</script>

<template>
  <aside class="fixed w-14 inset-y-0 left-0 flex flex-col z-10">
    <nav class="h-full flex flex-col items-center gap-4 px-2 sm:py-3.5">
      <NuxtLink 
        to="/"
        external
        class="bg-white rounded-full p-0.5"
      >
        <img 
          :src="Favicon" 
          class="w-8 h-8 rounded-full sm:w-8 sm:h-8"
          alt="BlazingFast"
        >
      </NuxtLink>
      <template
        v-for="navItem in userStore.navigationItems"
        :key="navItem.label" 
      >
        <NuxtTooltip
          :text="navItem.label"
          :popper="{
            placement: 'right',
          }"
          :ui="{
            wrapper: navItem.path === '/subscriptions/plans' ? 'mt-auto' : '',
            background: 'bg-gray-900',
            color: 'text-white',
          }"
        >
          <NuxtChip
            :show="navItem.path === '/inbox' && unreadMessageCount > 0"
            :text="unreadMessageCount"
            color="red"
            size="lg"
            :ui="{
              position: {
                'top-right': 'top-1 right-1',
              },
              size: {
                lg: 'text-[9px]',
              }
            }"
          >
            <NuxtLink
              class="flex h-9 w-9 items-center justify-center rounded-full" 
              :class="route.path === navItem.path ? 'bg-blue-200/75' : 'bg-white'" 
              :to="navItem.path"
              external
              :disabled="navItem?.disabled ?? false"
            >
              <NuxtIcon 
                class="w-[22px] h-[22px] transition-colors" 
                :class="route.path === navItem.path ? 'text-blue-700' : 'text-gray-500 hover:text-blue-800'" 
                :name="navItem.icon" 
                dynamic
              />
            </NuxtLink>
          </NuxtChip>
        </NuxtTooltip>
      </template>
    </nav>
  </aside>
</template>

<style scoped>

</style>