<script setup lang="ts">
import Favicon from '~/assets/img/favicon.png';
import { useUserStore } from "~/stores/user";

const route = useRoute();
const userStore = useUserStore();
</script>

<template>
  <aside class="relative w-full h-full z-10 text-md">
    <nav class="h-full flex flex-col items-start gap-4 px-2 py-4 sm:py-3.5">
      <NuxtLink 
        to="/"
        external
        class="bg-white rounded-full p-0.5"
      >
        <img 
          :src="Favicon" 
          class="w-8 h-8 rounded-full sm:w-8 sm:h-8"
          alt="BlazingFast"
        >
      </NuxtLink>
      <div class="absolute top-4 right-4">
        <NuxtButton 
          color="gray" 
          variant="ghost" 
          icon="i-heroicons-x-mark-20-solid" 
          size="lg"
          @click="$emit('close')" 
        />
      </div>
      <template
        v-for="navItem in userStore.navigationItems"
        :key="navItem.label" 
      >
        <NuxtLink
          class="flex items-center justify-center gap-3 p-2 bg-white" 
          :class="[
            route.path === navItem.path ? 'text-blue-500' : '',
            navItem.path === '/subscriptions/plans' ? 'mt-auto' : ''
          ]" 
          external
          :to="navItem.path"
          :disabled="navItem?.disabled ?? false"
        >
          <NuxtIcon 
            class="w-[22px] h-[22px] transition-colors" 
            :class="route.path === navItem.path ? 'text-blue-700' : 'text-gray-500 hover:text-blue-800'" 
            :name="navItem.icon" 
            dynamic
          />
          <span>{{ navItem.label }}</span>
        </NuxtLink>
      </template>
    </nav>
  </aside>
</template>

<style scoped>

</style>