<script setup lang="ts">
import { useUserStore  } from "~/stores/user";

const layoutCustomProps = useAttrs();
const { $supabaseClient } = useNuxtApp();

const userStore = useUserStore();

await useAsyncData('userWorkspaces', () => userStore.fetchWorkspaces())

const userTotalUnreadChannel = ref<any>();
const totalUnreadCount = ref(0);

async function fetchTotalUnreadCount() {
  try {
    const { data, error } = await $supabaseClient
      .from('user_total_unread')
      .select('total_unread')
      .eq('user_workspace_id', userStore.currentWorkspace?.user_workspace_id)
      .maybeSingle();
    if (error) throw error;
    totalUnreadCount.value = data?.total_unread ?? 0;
  } catch (error) {
    console.error(error);
  }
}

function subscribeToUserTotalUnreadCount() {
  const channel = $supabaseClient
    .channel('user-total-unread-message-count')
    .on('postgres_changes', {
      event: '*',
      schema: 'public',
      table: 'user_total_unread',
      filter: `user_workspace_id=eq.${userStore.currentWorkspace?.user_workspace_id}`,
    }, (payload) => {
      // console.log('[User Total Unread Channel]', payload);
      totalUnreadCount.value = payload.new?.total_unread ?? 0;
    })
    .subscribe((status, err) => {
      if (err) throw err;
      // console.log(`[User Total Unread Channel: ${status}]`, userStore.currentWorkspace?.user_workspace_id);
    });
  userTotalUnreadChannel.value = channel;
}

onBeforeMount(() => {
  fetchTotalUnreadCount();
  subscribeToUserTotalUnreadCount();
})

onUnmounted(() => {
  if (userTotalUnreadChannel.value) {
    $supabaseClient.removeChannel(userTotalUnreadChannel.value);
  }
})
</script>

<template>
  <div 
    class="w-full min-h-screen flex flex-col overflow-hidden"
    style="background: linear-gradient(267deg, #efefff 0%, #ebf5ff 100%)"
  >
    <NuxtSlideover
      v-model="userStore.isOpenMobileSidebar"
      side="left"
      :ui="{
        wrapper: 'w-[350px] block sm:hidden',
        base: 'w-[350px]',
        width: 'w-[350px]',
      }"
    >
      <LayoutMobileSidebar 
        @close="userStore.isOpenMobileSidebar = false"
      />
    </NuxtSlideover>
    <LayoutSidebar 
      class="hidden sm:block" 
      :unread-message-count="totalUnreadCount"
    />
    <div class="h-screen flex flex-col sm:pb-4 sm:pl-14">
      <LayoutHeader />
      <main 
        class="grid flex-1 items-start gap-4 bg-white h-[calc(100vh-48px)] overflow-y-auto rounded-xl shadow-sm sm:ml-2 sm:mr-6 md:gap-8"
        :class="{
          'p-3 sm:p-6': !layoutCustomProps?.['no-spacing'],
        }"
      >
        <slot />
      </main>
    </div>
  </div>
</template>

<style scoped>

</style>